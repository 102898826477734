import React from "react";
import { Grid } from "@mui/material";
import ProcessButton from "./ProcessButton";

const processes = [
  { id: "P_01", title: "Gestión de la Calidad" },
  { id: "P_02", title: "Planeamiento y Desarrollo" },
  { id: "P_03", title: "Infraestructura" },
  { id: "P_04", title: "Logística" },
  { id: "P_05", title: "Gestión de Procesos Académicos y Docencia" },
  { id: "P_06", title: "Gestión de Potencial Humano" },
  { id: "P_07", title: "Biblioteca y Servicios Académicos" },
  { id: "P_08", title: "Tesorería" },
  { id: "P_09", title: "Secretaría General" },
  { id: "P_10", title: "Admisión y marketing" },
  { id: "P_11", title: "Tecnologías de la Información" },
  { id: "P_12", title: "Matricula de estudiante" },
  { id: "P_13", title: "Orientación del Estudiante y Tutoría" },
  { id: "P_14", title: "Responsabilidad Social Universitaria" },
  { id: "P_15", title: "Contratación Personal Académico" },
  { id: "P_16", title: "Inducción Nuevo Personal Académico" },
  { id: "P_17", title: "Capacitación Docente" },
  { id: "P_18", title: "Enseñanza y Evaluación" },
  { id: "P_19", title: "Movilidad Estudiantil" },
  { id: "P_20", title: "Seguimiento a Egresados" },
  { id: "P_21", title: "Gestión de Trabajos de Investigación" },
  { id: "P_22", title: "Gestión de la Carrera o Programa" },
  { id: "P_24", title: "Movilidad Docente" },
];

const ProcessGrid = ({ onProcessClick }) => {
    return (
      <Grid container spacing={3} justifyContent="center">
        {processes.map((process) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={process.id}>
            <ProcessButton title={process.title} idProcess={process.id} onClick={() => onProcessClick(process)} />
          </Grid>
        ))}
      </Grid>
    );
  };
  
  export default ProcessGrid;
