import React from "react";
import { Typography, Box } from "@mui/material";

const Header = () => {
  return (
    <Box sx={{ textAlign: "center", mb: 4 }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: "#000000",
        }}
      >
        REPOSITORIO DIGITAL-INFORMACION DOCUMENTADA DEL SGC
      </Typography>
    </Box>
  );
};

export default Header;
