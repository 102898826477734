import React from "react";
import { Button } from "@mui/material";
import { motion } from "framer-motion";

const ProcessButton = ({ title,idProcess, onClick }) => {
  return (
    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
      <Button
        fullWidth
        variant="contained"
        onClick={onClick}
        sx={{
          backgroundColor: "#002e66",
          color: "#f9c51b",
          fontWeight: "bold",
          borderRadius: 2,
          padding: 2,
          textTransform: "none",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          "&:hover": {
            backgroundColor: "#f9c51b",
            color: "#002e66",
          },
        }}
      >
        {idProcess} {title}
      </Button>
    </motion.div>
  );
};

export default ProcessButton;
