import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { data } from "../data/data"; // Asegúrate de que data es un arreglo de objetos JSON.

const ModalDialog = ({ open, handleClose, process }) => {
  const [selectedTipoDocumento, setSelectedTipoDocumento] = useState("");
  const [optionDescription, setOptionDescription] = useState("");
  const [showTable, setShowTable] = useState(false); // Estado para controlar si se muestra la tabla
  const [scrollPosition, setScrollPosition] = useState(0); // Control de posición de scroll

  // Filtrar los datos según el proceso seleccionado
  const filteredData = data.filter(
    (item) =>
      item.DEPENDENCIA === process?.title &&
      (optionDescription ? item.TIPO_DOCUMENTO === optionDescription : true)
  );

  // Obtener las opciones únicas de TIPO_DOCUMENTO para el select
  const tipoDocumentoOptions = [
    ...new Set(
      data
        .filter((item) => item.DEPENDENCIA === process?.title)
        .map((item) => item.TIPO_DOCUMENTO)
    ),
  ];

  // Manejar el clic en el botón "Seleccionar"
  const handleSelectClick = () => {
    if (selectedTipoDocumento) {
      setOptionDescription(selectedTipoDocumento);
      setShowTable(true);
    } else {
      setShowTable(false); // No mostrar la tabla si no se selecciona nada
    }
  };

  // Función para hacer scroll hacia abajo en la tabla
  const handleScrollDown = () => {
    const tableBody = document.getElementById("table-body");
    if (tableBody) {
      tableBody.scrollTop = tableBody.scrollHeight;
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: 800,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
          {process ? `${process.title}` : "Selecciona un proceso"}
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Seleccione una opción:
        </Typography>

        {/* Select dinámico */}
        <Select
          displayEmpty
          fullWidth
          sx={{ mb: 2 }}
          value={selectedTipoDocumento}
          onChange={(e) => setSelectedTipoDocumento(e.target.value)}
        >
          <MenuItem value="">-- Seleccione --</MenuItem>
          {tipoDocumentoOptions.map((tipo, index) => (
            <MenuItem key={index} value={tipo}>
              {tipo}
            </MenuItem>
          ))}
        </Select>

        {/* Botón para mostrar la tabla */}
        <Button
          variant="contained"
          color="success"
          fullWidth
          sx={{ mb: 2 }}
          onClick={handleSelectClick}
        >
          Seleccionar
        </Button>

        {/* Tabla */}
        {showTable && filteredData.length > 0 && (
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: 400, // Altura máxima de la tabla, ajusta según tus necesidades
              overflowY: "auto", // Habilita el scroll vertical
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Dependencia</TableCell>
                  <TableCell>Tipo Documento</TableCell>
                  <TableCell>Versión</TableCell>
                  <TableCell>Fecha Elaboración</TableCell>
                  <TableCell>Fecha Actualización</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Código</TableCell>
                  <TableCell>Enlace</TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="table-body">
                {/* Mostrar datos filtrados */}
                {filteredData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.DEPENDENCIA}</TableCell>
                    <TableCell>{item.TIPO_DOCUMENTO}</TableCell>
                    <TableCell>{item.VERSION}</TableCell>
                    <TableCell>{item.FECHA_ELAVORACION}</TableCell>
                    <TableCell>{item.FECHA_ACTUALIZACION}</TableCell>
                    <TableCell>{item.DESCRIPCION}</TableCell>
                    <TableCell>{item.ESTADO}</TableCell>
                    <TableCell>{item.CODIGO_BUSQUEDA}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => window.open(item.ENLACE, "_blank")}
                      >
                        Ver
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Si no hay datos filtrados, mostrar un mensaje */}
        {showTable && filteredData.length === 0 && (
          <Typography variant="body2" color="textSecondary">
            No se encontraron documentos para la opción seleccionada.
          </Typography>
        )}

        {/* Botón de scroll hacia abajo */}
        {showTable && filteredData.length > 10 && (
          <Button
            variant="contained"
            color="primary"
            sx={{
              position: "absolute",
              bottom: 20,
              right: 20,
            }}
            onClick={handleScrollDown}
          >
            Bajar
          </Button>
        )}
      </Box>
    </Modal>
  );
};

export default ModalDialog;
