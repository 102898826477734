export const data = [
    {
        "DEPENDENCIA": "Infraestructura",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Infraestructura",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_03",
        "ENLACE": "https://drive.google.com/file/d/1vZNa-AZmzNid__qlOPtAxtfETZl64jqC/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Infraestructura",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Elaboraci\u00f3n de proyectos por recursos propios",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_03_PR_01",
        "ENLACE": "https://drive.google.com/file/d/1l-jJfS9kyJcM2LKoiWT-EqTLYhIOjH_3/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Infraestructura",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Elaboraci\u00f3n de proyectos por tercerizaci\u00f3n",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_03_PR_02",
        "ENLACE": "https://drive.google.com/file/d/1AJfLAK5Ak_Inp6IA7WiTrT90UqjTsF4Y/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Infraestructura",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Mantenimiento",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_03_PR_03",
        "ENLACE": "https://drive.google.com/file/d/1s-rDLdI3oEGXMEFG2EoSlf3kw_OJTdgq/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Infraestructura",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Reporte y tratamiento de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_01",
        "ENLACE": "https://drive.google.com/file/d/1MenTtFhVGMmfGPB6UKZRC-mZECSuBce5/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Infraestructura",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Registro para el control de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_02",
        "ENLACE": "https://drive.google.com/file/d/1qeSYKFVgxEeZm2G8XMJU4E8DEOyc5e-K/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Logística",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Adquisici\u00f3n de bienes, servicios y obras por proceso de selecci\u00f3n",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_04_PR_09",
        "ENLACE": "https://drive.google.com/file/d/1Z9U2ZXMh76gNfVzQT_epat_2ArdYasyW/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Logística",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Adquisici\u00f3n de bienes, servicios y obras por proceso directo",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_04_PR_10",
        "ENLACE": "https://drive.google.com/file/d/16ndpN_V4ugpnPJdTMOiYFC1jxnlS_5PV/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Logística",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Recepci\u00f3n e internamiento de bienes en almac\u00e9n",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_04_PR_01",
        "ENLACE": "https://drive.google.com/file/d/1756OFkRlnALbz4DfwPlRAERYdl2FFUGT/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Logística",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Distribuci\u00f3n de bienes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_04_PR_02",
        "ENLACE": "https://drive.google.com/file/d/1SwTe2kXkTpBOU6OExSu8Kg5c3U0L7d4Q/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Logística",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Inventario y generaci\u00f3n de informe de almac\u00e9n",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_04_PR_03",
        "ENLACE": "https://drive.google.com/file/d/1yxW4wJVRYWWzmdTOVToDOIlqFt7ttIx-/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Logística",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Enajenaci\u00f3n de bienes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_04_PR_04",
        "ENLACE": "https://drive.google.com/file/d/17a-7Gl3TEaKxA2udE0vUIhJxpJw4a4Gz/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Logística",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Inventario de bienes patrimoniales",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_04_PR_05",
        "ENLACE": "https://drive.google.com/file/d/1uwVyA62JoLLARgfzA8Ke9Z4M-31JSDTt/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Logística",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Baja de bienes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_04_PR_06",
        "ENLACE": "https://drive.google.com/file/d/1l3GrqbRpoZKAfGR3ErtINwLiOHzURmwf/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Logística",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Codificaci\u00f3n, identificaci\u00f3n y registro de bienes sujetos a alta",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_04_PR_07",
        "ENLACE": "https://drive.google.com/file/d/1DIRZuBNn0ydBzCJZIB_B7CLRtS6VrSfL/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Logística",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Codificaci\u00f3n, identificaci\u00f3n y registro de bienes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_04_PR_08",
        "ENLACE": "https://drive.google.com/file/d/10WVF-aon3kLEkZ-AK_WsDXeCorpU6ECk/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Logística",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Reporte y tratamiento de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_01",
        "ENLACE": "https://drive.google.com/file/d/1iuvnseBvhgRn23fgtf0c0FBisiJEadwR/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Logística",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Registro para el control de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_02",
        "ENLACE": "https://drive.google.com/file/d/1J2mveezAC9fW_ReMn0pt9IDStf_DDTcg/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de Potencial Humano",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Gesti\u00f3n de potencial humano",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_06",
        "ENLACE": "https://drive.google.com/file/d/12rYm_y2jfK9iAYk8wJ86SyvkUT18ok7z/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de Potencial Humano",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Contrataci\u00f3n personal administrativo",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_06_PR_01",
        "ENLACE": "https://drive.google.com/file/d/1tZWbL9MMkTbErXwHKyq08Qp3U3oEeU3j/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de Potencial Humano",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Contrataci\u00f3n personal docente",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_06_PR_02",
        "ENLACE": "https://drive.google.com/file/d/1uEsYkojA5xs6d4fVQS6LNyJN0ewRL35K/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de Potencial Humano",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Capacitaci\u00f3n personal administrativo",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_06_PR_03",
        "ENLACE": "https://drive.google.com/file/d/1kr5RnyGaP60LCLLaqwqxDZv0_jNZKxD2/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de Potencial Humano",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Reporte y tratamiento de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_01",
        "ENLACE": "https://drive.google.com/file/d/1DKpORtJSSKZoAe6Cwdq1VkPu7_BfJc5j/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de Potencial Humano",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Registro para el control de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_02",
        "ENLACE": "https://drive.google.com/file/d/18m_f1Tn3sY7YXPWF6l-girp-ptCJrL0k/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tesorería",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Tesorer\u00eda",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_08",
        "ENLACE": "https://drive.google.com/file/d/1wf55rzS67ehwIv3Ee-YflOWb7z4qvui3/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tesorería",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Recepci\u00f3n de documentos",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_08_PR_01",
        "ENLACE": "https://drive.google.com/file/d/1Y-Q_L8X1y4nu_meA7UJ2Maqye7bGOacT/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tesorería",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Seguimiento de documentos",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_08_PR_02",
        "ENLACE": "https://drive.google.com/file/d/1697braNKWOF-dp_0hUDF224upeU0hRCm/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tesorería",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Emisi\u00f3n de notas de cr\u00e9dito",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_08_PR_03",
        "ENLACE": "https://drive.google.com/file/d/1pj16WkILJNrBongHPa2kN8JzflmJwrmP/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tesorería",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Emisi\u00f3n de comprobantes de pago por diferentes servicios",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_08_PR_04",
        "ENLACE": "https://drive.google.com/file/d/1wIpSZG53DpEPfSOB8camYMFJ5jUm3swV/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tesorería",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Emisi\u00f3n de comprobantes de pago por pasarela, cierres diarios, mensuales y conciliaci\u00f3n",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_08_PR_05",
        "ENLACE": "https://drive.google.com/file/d/1XUKX-TS3UY7FsDgwvZ1KbQvemj3wfEEZ/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tesorería",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Envi\u00f3 diario a SUNAT de comprobantes de pago",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_08_PR_06",
        "ENLACE": "https://drive.google.com/file/d/1Q5HfBlaYCkF9aQIN-pRDqCNXUaLRht6G/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tesorería",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Revisi\u00f3n y conciliaci\u00f3n de cuentas",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_08_PR_07",
        "ENLACE": "https://drive.google.com/file/d/1vALRCTlUFDh7VJURcgOa05GVPvO63Akl/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tesorería",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Reporte y tratamiento de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_01",
        "ENLACE": "https://drive.google.com/file/d/1zoXgaaGuDIno87pQNSy_qnZnOsgIWDsH/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tesorería",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Registro para el control de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_02",
        "ENLACE": "https://drive.google.com/file/d/15PCkmmQwY0mC9aAgCtP12zn5nNS-d9u8/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Matricula de estudiante",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Matricula de estudiantes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_12",
        "ENLACE": "https://drive.google.com/file/d/1ncpjO2AmShSPB0MsWdrVKEZjgN80mmNE/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Orientación del Estudiante y Tutoría",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Orientaci\u00f3n del estudiante y tutor\u00eda",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_13",
        "ENLACE": "https://drive.google.com/file/d/1ppawPtpBlzGRdFEMaDtbwBsUi-gjl2bU/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Responsabilidad Social Universitaria",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Responsabilidad social universitaria",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_14",
        "ENLACE": "https://drive.google.com/file/d/1xM3YMWGCbgSP7gvC1RfTTFfkeIoy9GRe/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Contratación Personal Académico",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Contrataci\u00f3n de personal docente",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_15",
        "ENLACE": "https://drive.google.com/file/d/1MOxrgS3s1hgnS6yXhRxbcNwSOwslCh8R/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "nducción Nuevo Personal Académico",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Inducci\u00f3n nuevo personal docente",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_16",
        "ENLACE": "https://drive.google.com/file/d/1R53ejtZ1hmcV61MluoK9h4vyVsypp9dH/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Capacitación Docente",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Capacitaci\u00f3n docente",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_17",
        "ENLACE": "https://drive.google.com/file/d/1UCgJ1tye-g2pfJrePYLWZb9KzCYXk4UO/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Enseñanza y Evaluación",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Ense\u00f1anza y evaluaci\u00f3n",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_18",
        "ENLACE": "https://drive.google.com/file/d/1b5sTuoJKpCxfRsKzTwVqsL9iA8dR1WKr/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Movilidad Estudiantil",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Seguimiento a egresados",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_20",
        "ENLACE": "https://drive.google.com/file/d/19n5Nb_LxxQTn8yXUERp-7KL98EY0pLXg/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Seguimiento a Egresados",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Gesti\u00f3n trabajos de investigaci\u00f3n para obtenci\u00f3n de grados o t\u00edtulos",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_21",
        "ENLACE": "https://drive.google.com/file/d/1f5b0qKPhnPmoE-ULmsKgs-9G9lf0ZPiQ/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de Trabajos de Investigación",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Obtenci\u00f3n de grados acad\u00e9micos, t\u00edtulos profesionales y de segunda especialidad",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_21_PR_01",
        "ENLACE": "https://drive.google.com/file/d/1HC-NP0R91c3zaL3Z5iMidI_7hMa1hN1e/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Carrera o Programa",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Sustentaci\u00f3n para la obtenci\u00f3n de grados acad\u00e9micos, t\u00edtulos profesionales y de segunda especialidad",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_22",
        "ENLACE": "https://drive.google.com/file/d/1I63rfKrz3bOQDL4QZEcYaH9hDf7f_wsE/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Carrera o Programa",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Gesti\u00f3n de la carrera o programa",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_22",
        "ENLACE": "https://drive.google.com/file/d/1NNGQwlOYo0Xx94tnT0PMPm6xpl1IJ0Hj/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Carrera o Programa",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Creaci\u00f3n de la carrera o programa",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_22_PR_01",
        "ENLACE": "https://drive.google.com/file/d/1tSnE2iavM3WxNgbw7nNqbg518LpwVsBj/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Carrera o Programa",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Ratificaci\u00f3n, actualizaci\u00f3n y/o redise\u00f1o curricular",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_22_PR_02",
        "ENLACE": "https://drive.google.com/file/d/1Ht7zJioJDnZv5XpchHFrBwnaJ6rTmVo4/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Carrera o Programa",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Supresi\u00f3n de la carrera o programa",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_22_PR_03",
        "ENLACE": "https://drive.google.com/file/d/1i9VssOh0VBVYGR0LS7K_H5ZE7XWrmWM8/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Carrera o Programa",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Modificaci\u00f3n de denominaci\u00f3n de la carrera o programa",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_22_PR_04",
        "ENLACE": "https://drive.google.com/file/d/1RFHPvwxwq77UhIS9z7SeoCwChJvdE5sP/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Carrera o Programa",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Modificaci\u00f3n del programa y/o carrera de estudios y/o menci\u00f3n de grados y t\u00edtulos",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_22_PR_05",
        "ENLACE": "https://drive.google.com/file/d/1EwvtsEpCJvJfCYaegnY4iuSKt2FImVip/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Carrera o Programa",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Reporte y tratamiento de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_01",
        "ENLACE": "https://drive.google.com/file/d/1DogjSeQa-RAepf00m5eSrMztDWoTi335/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Facultad",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Registro para el control de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_02",
        "ENLACE": "https://drive.google.com/file/d/1ygT-TPtyiQTEgrSxUCut1cIRirHCp_2H/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Admisión y marketing",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Admisi\u00f3n",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_10",
        "ENLACE": "https://drive.google.com/file/d/1mX4xxULgz7qvY1-qBVZKrcPyJLv4ttXE/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Admisión y marketing",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Admisi\u00f3n",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_10_PR_01",
        "ENLACE": "https://drive.google.com/file/d/1yu3BYXBR4YpgOz2DV1WzkyxsnVxl7-dW/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Admisión y marketing",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Reporte y tratamiento de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_01",
        "ENLACE": "https://drive.google.com/file/d/12jZkLah-r9Xk89OUX54KzTWRyjeowOnO/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Admisión y marketing",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Registro para el control de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_02",
        "ENLACE": "https://drive.google.com/file/d/1GxVClBYhuY8D0yr2pWWEbbkvqFuUi1Yg/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Biblioteca y Servicios Académicos",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "biblioteca y servicios acad\u00e9micos",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_07",
        "ENLACE": "https://drive.google.com/file/d/1iWVn8DQASDLH1kyxXGUe0TUw5JwQe27A/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Biblioteca y Servicios Académicos",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Recepci\u00f3n de materia bibliogr\u00e1fico comprado para biblioteca",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_07_PR_01",
        "ENLACE": "https://drive.google.com/file/d/1itPDREvHdGJ1ecXfMRflRksSh3jc6pqZ/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Biblioteca y Servicios Académicos",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Recepci\u00f3n de materia bibliogr\u00e1fico donado",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_07_PR_02",
        "ENLACE": "https://drive.google.com/file/d/1WpuEMEdsjgSz9VT_eFh6L4n6L7GrHIW3/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Biblioteca y Servicios Académicos",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Reserva de material bibliogr\u00e1fico de la biblioteca de la UPT",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_07_PR_03",
        "ENLACE": "https://drive.google.com/file/d/1pQIN64w_JawqN9ZxOudrtV8LNqVDi3wF/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Biblioteca y Servicios Académicos",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Pr\u00e9stamo de material bibliogr\u00e1fico",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_07_PR_04",
        "ENLACE": "https://drive.google.com/file/d/1DNfo_ujsNZFszwUXpQgMj3a1W9SRH5wf/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Biblioteca y Servicios Académicos",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Devoluci\u00f3n de material bibliogr\u00e1fico",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_07_PR_05",
        "ENLACE": "https://drive.google.com/file/d/1-KcuzGKSRRmQKCW3kn2lWtHaQqjHf5pd/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Biblioteca y Servicios Académicos",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Reporte y tratamiento de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_01",
        "ENLACE": "https://drive.google.com/file/d/1q6q2O0aIgwFJer_IVKXDX9aT-vATkUGq/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Biblioteca y Servicios Académicos",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Registro para el control de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_02",
        "ENLACE": "https://drive.google.com/file/d/1WWx3a87Ivq28eq78a8vmP3Ed3MtJtHhj/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "MANUAL",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": null,
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1xN-ut7jzZjick_PdPbxymbZmvpgZk116/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Gesti\u00f3n de la Calidad",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/15Mtj3ku92lRknpfGLNCo1kRJRrpcwuvr/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Control de Informaci\u00f3n Documentada",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_01",
        "ENLACE": "https://drive.google.com/file/d/1O5JXs2w9KMZKrWMxrv4grRmpfW21fIyb/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Auditor\u00eda interna",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_02",
        "ENLACE": "https://drive.google.com/file/d/1wnWsRR8wtdeLTMj2Qx2F7Fb87e5hatZS/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Programa Anual de Auditor\u00edas",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_02_F_01",
        "ENLACE": "https://drive.google.com/file/d/1l7WVuAOR_hjlG5c2WTDX3xLo-XN6asUi/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Plan de Auditor\u00eda",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_02_F_02",
        "ENLACE": "https://drive.google.com/file/d/1s9zX_0pJTZ5pfDHCMNukZ8MIfy6ehwVd/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "INFORME DE AUDITOR\u00cdA",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_02_F_04",
        "ENLACE": "https://drive.google.com/file/d/14Wk7Vg-fNfP-KtD1-1_qd20w0YBdExV4/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "LISTA DE AUDITORES INTERNOS",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_02_F_05",
        "ENLACE": "https://drive.google.com/file/d/1F48Sw65ljifw9_yqSziAJ-6I212fKDXp/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "LISTA DE VERIFICACI\u00d3N",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_02_F_06",
        "ENLACE": "https://drive.google.com/file/d/1H5ECNrqWklDdtWGjv2K5bWAs8Yp2ksW0/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "No conformidades y Acciones correctivas",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_03",
        "ENLACE": "https://drive.google.com/file/d/1aSj8bQoA8baaCzL0lh4jXsGIN-N0oRoL/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Solicitud de acci\u00f3n correctiva",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_03_F_01",
        "ENLACE": "https://drive.google.com/file/d/1jveAXJpMo760IhqL2ejUzSGhE_YhEjGe/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Control de solicitudes de acciones correctivas",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_03_F_02",
        "ENLACE": "https://drive.google.com/file/d/1535iKvEiuxSVw94KcfYqf4J1X47Uc4we/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Control de Salidas No Conforme",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04",
        "ENLACE": "https://drive.google.com/file/d/1LIfMKH0BdMiq1fHaDKJNGCgxuX_-wZMk/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Reporte y tratamiento de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_01",
        "ENLACE": "https://drive.google.com/file/d/1dQd-E5EBKNZQFaixpkS8Ujsm4W1PhNpM/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Registro para el control de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_02",
        "ENLACE": "https://drive.google.com/file/d/1N3kltkR1vV5S3rt5T9ZfaEHki5Y7j15S/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "MAPA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Mapa de procesos",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_MAPA_PROCESOS",
        "ENLACE": "https://drive.google.com/file/d/15AYoAyfgaPDDicKNiP6aAuxYQV51HBtY/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de la Calidad",
        "TIPO_DOCUMENTO": "MATRIZ",
        "VERSION": 1.0,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Matriz de riesgos y oportunidades",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_M_001_RIESGOS INTERNOS",
        "ENLACE": "https://drive.google.com/file/d/1ft1z8kpqOQFGXzexKa-T9l7s_qMowR8z/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de Procesos Académicos y Docencia",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Gesti\u00f3n de procesos acad\u00e9micos y docencia",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_05",
        "ENLACE": "https://drive.google.com/file/d/17CIjjJ9NpdW27cpIYyXozb3GqlkfRjeW/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Gestión de Procesos Académicos y Docencia",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Gesti\u00f3n de procesos acad\u00e9micos y docencia",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_05_PR_01",
        "ENLACE": "https://drive.google.com/file/d/1dFeLU9RJpZMkyL2CuKqNDDWRgHzhVrEO/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Planeamiento y Desarrollo",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Planeamiento y Desarrollo",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_02",
        "ENLACE": "https://drive.google.com/file/d/1_DKyaeLxT3CCkSLNvBMSBaNNsXJFc3w1/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Planeamiento y Desarrollo",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Plan Estrat\u00e9gico Institucional",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_02_PR_01",
        "ENLACE": "https://drive.google.com/file/d/1JKY8ZAoRCuLUVNvnUfaxommtzjFHxcPJ/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Planeamiento y Desarrollo",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Plan Operativo Institucional",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_02_PR_02",
        "ENLACE": "https://drive.google.com/file/d/1H2ohg4CA4nftOyd5xLYpC0fudngHw02s/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Planeamiento y Desarrollo",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Presupuesto Institucional",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_02_PR_03",
        "ENLACE": "https://drive.google.com/file/d/1gf1b--u6I7vAys3vSPOnYc_dbiGGCIVm/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Planeamiento y Desarrollo",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Plan Anual de Trabajo",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_02_PR_04",
        "ENLACE": "https://drive.google.com/file/d/1sAL9D_USMzF21-HpZHhVx1IXHi1AVcvU/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Planeamiento y Desarrollo",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Reporte y tratamiento de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_01",
        "ENLACE": "https://drive.google.com/file/d/16FyC-Iv0xnGKg2Z2jqhGnQuE7dJxqiqw/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Planeamiento y Desarrollo",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Registro para el control de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_02",
        "ENLACE": "https://drive.google.com/file/d/1mFlVO4dk_INmY-dH9KZgFmIl8MTTHjIy/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Secretaría General",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Secretaria general",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_09",
        "ENLACE": "https://drive.google.com/file/d/1k5FNjby9XC4SMrPJ5vml5MA1VqtmTaI7/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Secretaría General",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Mesa de partes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_09_PR_01",
        "ENLACE": "https://drive.google.com/file/d/191T_P5w_Elk8-KbpEWiYK_G7olwul_oK/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Secretaría General",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Expedientes y tramitaci\u00f3n de Grados y T\u00edtulos",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_09_PR_02",
        "ENLACE": "https://drive.google.com/file/d/1oZGXnwCzko969GpxsY3ng3EBg7Dru7y5/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Secretaría General",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Reporte y tratamiento de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_01",
        "ENLACE": "https://drive.google.com/file/d/1Azf3XIRKMJTPCe6pJs-CYKvRFNqbRmHQ/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Secretaría General",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Registro para el control de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_02",
        "ENLACE": "https://drive.google.com/file/d/1ccU39Mdv0P6HDf2XDD_mv-JISqsUZ3nV/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tecnologías de la Información",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Tecnolog\u00edas de la informaci\u00f3n",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_11",
        "ENLACE": "https://drive.google.com/file/d/1DRUHia0qVuUuS5UahM2mJ83BBe-wMZcq/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tecnologías de la Información",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Planeamiento y desarrollo de sistemas",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_11_PR_01",
        "ENLACE": "https://drive.google.com/file/d/1bCYVy8ECfMWUTVMCvyEUOWo6tGVa_UVS/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tecnologías de la Información",
        "TIPO_DOCUMENTO": "PROCEDIMIENTOS",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Mantenimiento de equipos de computo",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_11_PR_02",
        "ENLACE": "https://drive.google.com/file/d/1a6flVU6VBuNgoAlM8xvXJv_a0L3W58ax/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tecnologías de la Información",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Reporte y tratamiento de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_01",
        "ENLACE": "https://drive.google.com/file/d/189UBaIJJeAG4X2xZQ8GU26F8b3d_T5nH/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Tecnologías de la Información",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Registro para el control de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_02",
        "ENLACE": "https://drive.google.com/file/d/1AJUVLZ-ctmvF8s8pyvx8sJKz0-yZlJG3/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "RELACIONESNACIONALESEINTERNACIONALES",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Movilidad estudiantil",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_19",
        "ENLACE": "https://drive.google.com/file/d/1XYwPqVKxEumkpQ1vUAwIUgzVmGxQ6MUj/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "Movilidad Docente",
        "TIPO_DOCUMENTO": "PROCESO",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Movilidad docente",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_24",
        "ENLACE": "https://drive.google.com/file/d/1_5IGaCilQj1fI3lEWMmhTi-7TBo8XUGj/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "RELACIONESNACIONALESEINTERNACIONALES",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Reporte y tratamiento de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_01",
        "ENLACE": "https://drive.google.com/file/d/1jmx16YS3mwEXPf0F5FuziJQHDAAbTAd-/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "RELACIONESNACIONALESEINTERNACIONALES",
        "TIPO_DOCUMENTO": "FICHA",
        "VERSION": 1.1,
        "FECHA_ELAVORACION": "2022-05-01T00:00:00",
        "FECHA_ACTUALIZACION": "2023-07-01T00:00:00",
        "DESCRIPCION": "Registro para el control de Salidas No Conformes",
        "ESTADO": "VIGENTE",
        "CODIGO_BUSQUEDA": "P_01_PR_04_F_02",
        "ENLACE": "https://drive.google.com/file/d/1x-TWFm0jk6qkKMGE3Eqn_768dSy5vtFn/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Plan auditoria - externa - PLANES AUDITOR\u00cdA DE CERTIFICACI\u00d3N - 2022 - PLAN DE AUDITOR\u00cdA FASE 1",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/18JBqC_We1uePNtS3k5MgEcUp4Dn84DWw/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Plan auditoria - externa - PLANES AUDITOR\u00cdA DE CERTIFICACI\u00d3N - 2022 - PLAN DE AUDITOR\u00cdA FASE 2",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1ndT82YNTkNTVi3xTPtcLIekBE8zGeMH_/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Informes auditoria - interna - INFORME FINAL DE AUDITORIA INTERNA 2022",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1Ha0rXTVOS1hegxYF8CQ3dth8951csIoN/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Informes auditoria - interna - INFORME FINAL DE AUDITOR\u00cdA INTERNA ISO - 2022",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1o9_orXOKuccpatkqn396kU7i0cjC64q4/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Informes auditoria - interna - INFORME DE AUDITOR\u00cdA INTERNA A GECA 2023",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/18TG9AQ0SJ89zPqZ8JX5ZlWV9nE8tkUV1/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Informes auditoria - externa - Informe de Auditoria.v0 informe de mantenimiento N 01 - 2023",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1R6r-fEuKuAAzgzp9pxYybnflruHWqTai/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Informes auditoria - externa - AUDITOR\u00cdA DE CERTIFICACI\u00d3N - 2022 - INFORME AUDITORIA F1",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1c_0beIurkbZ1H1IehXbKnFJKx0hDLTNj/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Informes auditoria - externa - AUDITOR\u00cdA DE CERTIFICACI\u00d3N - 2022 - INFORME FINAL DE AUDITORIA ISO 9001 2015 CERTHIA F2 2022",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/15EJT2NeX6Pj8VVJYnmD6qdSJALv6tytC/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Informes auditoria - externa - AUDITOR\u00cdA DE CERTIFICACI\u00d3N - 2022 - RR. 1647-2022-UPT-R INFORME FINAL DEL PROCESO DE CERTIFICACION",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1iVN6w0bTAVjMR8eSlZFzLbDNoVw6guZM/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Programa de Auditor\u00eda - RR 1251-2023-R PROGRAMA DE AUDITORIA SGC 2023",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/11Dhs1r4CkhjKsQ7hT-K7uY8fC5RQPCn5/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Programa de Auditor\u00eda - RR 1712-2024-UPT-R PROGRAMA DE AUDITORIA",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/15ez8yeSTAdmSrt8XjJLLoClsdjrgP-Ld/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Plan auditoria - PLAN DE AUDITOR\u00cdA INTERNA - PLAN DE AUDITORIA INTERNA SGC 2022",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1zu32b1vsUaJwX6SE5DfdWji_W2FI_ApN/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Plan auditoria - PLAN DE AUDITOR\u00cdA INTERNA - PLAN DE AUDITORIA INTERNA SGC 2023",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1ynzvl6XtyB1gj9P7Dxs3v_wXoSbFyWDo/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Plan auditoria - PLAN DE AUDITOR\u00cdA INTERNA - PLAN DE AUDITORIA INTERNA SGC 2024",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1OqaxlOxGlpV5BSMNYoNNgGEMuyUIBLkK/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Plan auditoria - externa - PLAN DE AUDITORIA MANTENCION N\u00b0 01-2023",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1w5JFRv0LU6WHvoPXCnBt0eOMvieoa-5I/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "AUDITOR\u00cdAS",
        "TIPO_DOCUMENTO": "AUDITOR\u00cdAS",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "Plan auditoria - Revisi\u00f3n por la Direcci\u00f3n - REVISI\u00d3N POR LA DIRECCI\u00d3N - AGOSTO 2023",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1uU1DC_QUSz7Aen2dcX7dy_yg1XQC-2lN/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - INFRACCIONES Y SANCIONES - SUNEDU - Reglamento de infracciones y sanciones de la SUNEDU",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1R2bK2ucNTHwGcF2WecJcM5Cg8x9OZPx_/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - INFRACCIONES Y SANCIONES - SUNEDU - Reglamento para el registro de Infractores y Sanciones de la Sunedu",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/10yYu2zYwvN8oSagj72oykzFpH9PVNK3J/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - INFRAESTRUCTURA - Reglamento nacional de edificaciones actualizado",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/19UC9bxQ1kAGH5SK8obI-ZstqX6drtQHz/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - ISO 9000:2015 - NORMA ISO 9001 2015",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1-2QrIQfkk1NF00Br2JvSa6pK0bO9M9DH/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - LEY UNIVERSITARIA Y MODIFICATORIAS - Ley universitaria 30220",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1Cu9G6X7vGZ9Z0l12GGfPfs_ZrvTbWpZ3/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - LEY UNIVERSITARIA Y MODIFICATORIAS - Ley-31051-ACCESO A CUIDADO INFANTIL",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1VfLVNPrJ-l_pNABz0Lv0kKu5oYYEHN9n/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - LEY UNIVERSITARIA Y MODIFICATORIAS - Ley-31803-PROMOCI\u00d3N DE INVESTIGACI\u00d3N PARA GRADOS Y T\u00cdTULOS",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1KwcRMb0JOZIs8wuzpJSpMntDQU1nH5z6/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - LEY UNIVERSITARIA Y MODIFICATORIAS - Ley-32105-LICENCIAMIENTO PERMANENTE-100% DE VIRTUALIDAD",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1BiBH-pAXATAW0uYSwjzP8VRTAaJe3ldG/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - D.S. N\u00ba 002-94-JUS-Procedimientos administrativos",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1VOwcsHMWSXMu_YEGzXLSZP0OVyy-SCP5/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - DS_007_2002_TR - Decreto legislativo 854",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1tQBjehZKWb97nKtI5eJcHg_jsaCd5P3P/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - REGLAMENTO INTERNO DE TRABAJO (VIGENTE)",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1PRa5ty6bA0uZPQECVbjUfLSv5WgY_hii/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - DECRETO LEGISLATIVO 688 BENEFICIOS SOCIALES Y MODIFICATORIAS - Decreto legislativo 688 - beneficios sociales",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/16U942pyoViy6foQWi-QeqNyJEjqTbXPM/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - DECRETO LEGISLATIVO 688 BENEFICIOS SOCIALES Y MODIFICATORIAS - Ley N\u00b031149 Modifica el DL N\u00b0688",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1dx2N0jTADqtmANoZpTsZQpvBgLFKUEYE/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - DECRETO LEGISLATIVO 713 DESCANSO REMUNERADOS Y MODIFICATORIAS - DECRETO LEGISLATIVO N\u00ba 713 - descansos remunerados",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1Rs9LFi3KoxC48ujLjLDT86TFD4FZZ7bg/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - DECRETO LEGISLATIVO 713 DESCANSO REMUNERADOS Y MODIFICATORIAS - Ley N\u00b031788 Modifica DL N\u00b0713",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/drive/folders/10qu28LBKeHeeN0WkFHrpeqDfjDuzJYdE"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - DECRETO LEGISLATIVO N\u00b0728 - TUO Decreto Legislativo N\u00b0728",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/10rbThb5u6QYPU21vtbv3fl8xnA_A1WiP/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - LEY 25593 RELACIONES COLECTIVAS DE TRABAJO Y MODIFICATORIAS - Decreto Ley 25593 Ley de Relaciones Colectivas de Trabajo",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1QLs-ZCoWCuYTtz1i-WfGLEVibbOQljmq/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - LEY 25593 RELACIONES COLECTIVAS DE TRABAJO Y MODIFICATORIAS - DS N\u00b0014-2022-TR Modifica el reglamento de la Ley 25593",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/18iIl8NQ6-Mfs4mbcHymaJo5mjBsLMUzJ/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - LEY 25593 RELACIONES COLECTIVAS DE TRABAJO Y MODIFICATORIAS - DS_011-92-TR-Reglamento de la Ley relaciones colectivas",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1Hv_ZoN-AT4JKquu0jTYOv4efnoIFj0K-/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - REGLAMENTO DE TRABAJOS CONDUCENTES A GRADOS Y T\u00cdTULOS - Modificatoria Reglamento de trabajos conducentes a grados y titulos - renati",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1EfB26XNMOMnbT7uWK6i_bql623f0ObJS/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - REGLAMENTO DE TRABAJOS CONDUCENTES A GRADOS Y T\u00cdTULOS - Reglamento de trabajos conducentes a grados y titulos - renati",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/15Fj7IxUfBtNL4XSkcnNghSid0bYcO-kK/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - REGLAMENTO DE TRABAJOS CONDUCENTES A GRADOS Y T\u00cdTULOS - Reglamento del Registro Nacional de Grados y T\u00edtulos",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/12X2onBgYNNfJ7aBxp8VrPyJcd5AaSp-t/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - REGLAMENTO PARA CESE DE ACTIVIDADES DE UNIVERSIDADES Y ESCUELAS DE POSTGRADO - Reglamento del Proceso de Cese de Actividades de Universidades y Escuelas de Posgrado",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1PN_8CtVh8ZqKjOyE0yFqhyEfkHlauu8Q/view?usp=sharing"
    },
    {
        "DEPENDENCIA": "DOE",
        "TIPO_DOCUMENTO": "DOE",
        "VERSION": null,
        "FECHA_ELAVORACION": null,
        "FECHA_ACTUALIZACION": null,
        "DESCRIPCION": "DOE - 2024 - RECURSOS HUMANOS - REGLAMENTO PARA CESE DE ACTIVIDADES DE UNIVERSIDADES Y ESCUELAS DE POSTGRADO - RES. 017-2023-SUNEDU-CD - Actualizaci\u00f3n",
        "ESTADO": null,
        "CODIGO_BUSQUEDA": "P_01",
        "ENLACE": "https://drive.google.com/file/d/1wDEMh_xVnB0ASGtTNRtUg5-lchvMukgQ/view?usp=sharing"
    }
]